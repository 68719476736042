import React from 'react';
import Helmet from 'react-helmet';
import MyNavbar from '../../components/navbar/navbar';

import pdf_file from '../../images/pdf/beslut-iso-3834-3.pdf';
import favicon from '../../images/favicon.ico';


function Beslut_iso_3834_3() {
    return (
        <>
            <Helmet>
                <title>ISO 3834-3 - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Certifikat ISO 3834-3 Danas Plåt och Smide AB" />
                <meta name="keywords" content="Certifikat | ISO 3834-3" />
                <meta property="og:title" content="Kontakt - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/beslut_iso_3834_3" />
                <link rel="cannonical" href="https://danasplat.se/beslut_iso_3834_3" />
            </Helmet>
            <MyNavbar/>
            <iframe 
                title="certifikat ISO 3834-3 Danas Plåt och Smide AB"
                src={pdf_file}
                style={{height: '100vh',
                        width: '100vw',
            }}>
            <p>This browser does not support PDFs. Please download the PDF to view it: Download PDF </p>
            </iframe>
        </>
    );
}
export default Beslut_iso_3834_3;